@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz@8..144&display=swap')

.Footer
    font-family: 'Roboto Flex', sans-serif
    background-color: #21212a
    position: fixed
    bottom: 0
    width: 100%
    padding: 20px
    box-shadow: -5px -5px 15px 5px rgba(0, 0, 0, 0.2)
    p
        color: #b4d5ddbd
        margin: auto
        width: min-content
        white-space: nowrap
        a
            color: #b4d5ddbd
