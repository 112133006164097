.Success {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  font-size: 14px;
}

.Success button {
  border-radius: 5px;
  border: none;
  padding: 5px;
  background-color: #5b85fa;
  color: white;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.Success button:hover {
  background-color: #13348e;
}
