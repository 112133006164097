@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz@8..144&display=swap");
.Landing {
  margin: auto;
  width: 100%;
}

.Landing .content {
  background-color: #ffffffbd;
  width: 430px;
  margin: auto;
  padding: 25px;
  border-radius: 20px 5px;
  border: 5px solid #0091ff96;
  color: #000a55;
  font-family: 'Roboto Flex', sans-serif;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 205px;
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.1);
          box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.1);
}

.Landing .content .title {
  letter-spacing: 1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Landing .content .title .test {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}

.Landing .content .title .test h1 {
  font-family: 'Bebas Neue', cursive;
  font-size: 70px;
  line-height: 70px;
}

.Landing .content .title .test span {
  font-family: 'Roboto Flex', sans-serif;
  font-size: 20px;
  font-weight: 300px;
}

.Landing .content .title form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}

.Landing .content .title form input {
  border-radius: 5px;
  border: none;
  padding: 5px;
}

.Landing .content .title form input[type=submit] {
  background-color: #5b85fa;
  color: white;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.Landing .content .title form input[type=submit]:hover {
  background-color: #13348e;
}
