.Success
    display: flex
    align-items: center
    gap: 10px
    font-size: 14px
    button
        border-radius: 5px
        border: none
        padding: 5px
        background-color: #5b85fa
        color: white
        cursor: pointer
        transition: all 0.2s ease-in-out
    button:hover
        background-color: #13348e
