@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz@8..144&display=swap')

.Landing
    margin: auto
    width: 100%
    .content
        background-color: #ffffffbd
        width: 430px
        margin: auto
        padding: 25px
        border-radius: 20px 5px
        border: 5px solid #0091ff96
        color: #000a55
        font-family: 'Roboto Flex', sans-serif
        display: flex
        flex-direction: column
        align-items: center
        height: 205px
        box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.1)
        .title
            letter-spacing: 1px
            display: flex
            flex-direction: column
            align-items: center
            .test
                display: flex
                gap: 20px
                margin-bottom: 15px
                h1
                    font-family: 'Bebas Neue', cursive
                    font-size: 70px
                    line-height: 70px
                span
                    font-family: 'Roboto Flex', sans-serif
                    font-size: 20px
                    font-weight: 300px
            form
                display: flex
                gap: 20px
                margin-bottom: 15px
                input
                    border-radius: 5px
                    border: none
                    padding: 5px
                input[type=submit]
                    background-color: #5b85fa
                    color: white
                    cursor: pointer
                    transition: all 0.2s ease-in-out
                input[type=submit]:hover
                    background-color: #13348e
